// eslint-disable-next-line no-redeclare,no-unused-vars
function buildIframePopup(title, src) {
  var iframe = $('<iframe src="' + src + '"></iframe>').css(
    'height',
    getScreenHeight() - 200
  );
  buildPopup({
    ident: 'iframe',
    title: title,
    content: iframe,
  });
}
